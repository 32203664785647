import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import "../styles/single.scss";
import SocialShare from "../components/SocialShare";
import { getParsedContent } from "../utils/contentParser";

export default class Page extends Component {
  render() {
    const { data } = this.props;
    // Parse the content to remove any domain names, .php, and replace old forms
    const parsedContent = getParsedContent(data.wordpressPage.content);

    return (
      <Layout templateName="page" relatedArticles={data.relatedArticles.edges}>
        <Seo
          title={data.wordpressPage.yoast_meta.yoast_wpseo_title}
          description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
          image={
            data.wordpressPage.featured_media &&
            data.wordpressPage.featured_media.localFile
              ? `${
                  process.env.NODE_ENV === "production"
                    ? data.site.siteMetadata.baseUrl
                    : typeof window !== "undefined" && window.location.host
                }${
                  data.wordpressPage.featured_media.localFile.childImageSharp
                    .original.src
                }`
              : ""
          }
          canonical={data.wordpressPage.yoast_meta.yoast_wpseo_canonical}
          schemaType="WebPage"
          datePublished={data.wordpressPage.date}
          dateModified={data.wordpressPage.modified}
        />
        <h1
          className="single__heading"
          dangerouslySetInnerHTML={{
            __html: data.wordpressPage.title,
          }}
        />
        <div className="single__meta">
          <div className="author">
            <div className="author__meta">{data.wordpressPage.date}</div>
          </div>
          {data.wordpressPage.acf.enable_social_links && (
            <SocialShare shareData={data.wordpressPage} />
          )}
        </div>
        <div
          className="single__content"
          dangerouslySetInnerHTML={{
            __html: parsedContent,
          }}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query PageQuery($slug: String!) {
    site {
      siteMetadata {
        baseUrl
      }
    }
    wordpressPage(slug: { eq: $slug }) {
      title
      content
      slug
      modified
      date(formatString: "MMMM D, YYYY")
      featured_media {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf {
        enable_social_links
      }
    }
    relatedArticles: allWordpressPost(limit: 4) {
      edges {
        node {
          title
          slug
          date(formatString: "MMMM D, YYYY")
          content
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
